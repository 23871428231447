import React from 'react';
import { withTranslation } from 'react-i18next';
import { Span } from '../Header/styles';


interface PackageLinkProps {
  t: (key: string) => string; 
}

export const PackageLink: React.FC<PackageLinkProps> = ({ t }) => {
  return (
    <a href="https://pilatropico.punchpass.com/passes" style={{color: "#DA726A"}}>
        <Span>{t("Packages")}</Span>
    </a>
  );
};

export default withTranslation()(PackageLink);