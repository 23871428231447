import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import {
  FooterSection,
  Para,
  Chat,
  Language,
} from "./styles";

// interface SocialLinkProps {
//   href: string;
//   src: string;
// }

const Footer = ({ t }: any) => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //       style={{marginLeft: "16px"}}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
            <Col lg={10} md={10} sm={12} xs={12}>
                <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <Para>
                <b id = "contactUs">
                </b>
              </Para>
              <a href="mailto:pilatropico.pr@gmail.com">
                <Chat>{t(`Email us!`)}</Chat>
              </a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
                <Language>{t("Address")}</Language>
              <Para>Pilatropico</Para>
              <Para>PR 2 Km 117.6</Para>
              <Para>Bo. Ceiba Baja</Para>
              <Para>Aguadilla, PR 00605</Para>
              <a href="https://maps.app.goo.gl/cq752y8iuoDfgmN47">
              <Para style={{ textDecoration: 'underline', fontSize: '20px' }}>Pilatrópico location</Para>
              </a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Social Media")}</Language>
              <a href="https://www.facebook.com/pilatropico.pr" >
              <Para>Facebook</Para>
              <Para><SvgIcon src="facebookColor.svg" width="25px" height="25px"/></Para>
              </a>
              <a href="https://www.instagram.com/pilatropico.pr">
              <Para>Instagram</Para>
              <Para><SvgIcon src="instagramColor.svg" width="25px" height="25px"/></Para>
              </a>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
